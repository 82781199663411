import React, { useState, useEffect, useRef } from "react";
import Peer from "peerjs";
import { saveAs } from "file-saver";
import { useParams, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  Form,
  Row,
  Col,
  Card,
  Button,
  notification,
  Result,
} from "antd";
// import LogoBid from "../assets/img/png/bidlab.png";
// import LogoCiti from "../assets/img/png/citifundation.png";
import VideoCameraOutlined from "@ant-design/icons/VideoCameraOutlined";
import PauseCircleOutlined from "@ant-design/icons/PauseCircleOutlined";
import PlayCircleOutlined from "@ant-design/icons/PlayCircleOutlined";
import DownloadOutlined from "@ant-design/icons/DownloadOutlined";
import AudioOutlined from "@ant-design/icons/AudioOutlined";
import AudioMutedOutlined from "@ant-design/icons/AudioMutedOutlined";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import CloseCircleOutlined from "@ant-design/icons/CloseCircleOutlined";
import FileImageOutlined from "@ant-design/icons/FileImageOutlined";
//import VideoCameraOutlined from "@ant-design/icons/VideoCameraOutlined";
// import mic from "../../../assets/img/png/mic.png";
// import micoff from "../../../assets/img/png/mic_off.png";
// import cam from "../../../assets/img/png/cam.png";
// import camoff from "../../../assets/img/png/cam_off.png";
import moment from "moment";

import "./Conference.scss";

// import { returnMailImage } from "../utils/functions";

import { getEventByUrlApi, UpdateEventApi } from "../../../api/event.api";
import ModalUser from "../../../components/Admin/ModalUser";
import errorpermisos from "../../../assets/img/png/error.png";

import { getAccessTokenApi } from "../../../api/auth";

export default function Conference() {
  const [event, setEvent] = useState();
  const [formPersonal] = Form.useForm();

  const [peerId, setPeerId] = useState("");
  const [remotePeerIdValue, setRemotePeerIdValue] = useState("");
  const remoteVideoRef = useRef(null);
  const currentUserVideoRef = useRef(null);
  const peerInstance = useRef(null);
  const mediaStreamRef = useRef(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [isStartVideo, setIsStartVideo] = useState(false);
  const [isDownload, setIsDownload] = useState(true);
  const [isInCall, setIsInCall] = useState(false);
  const [calling, setCalling] = useState(false);
  const [isRemoteConnect, setIsRemoteConnect] = useState(false);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [mediaStream, setMediaStream] = useState(null);
  //const [peerSave, setPeerSave] = useState(null);
  const [connection, setConnection] = useState(null);
  const canvasRef = useRef(null);
  const { evento } = useParams();

  const [videoClientEnabled, setVideoClientEnabled] = useState(true);
  const [audioClientEnabled, setAudioClientEnabled] = useState(true);
  //const [dataConnection, setDataConnection] = useState(null);

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();

  const [modalContent, setmodalContent] = useState();

  const navigate = useNavigate();

  const token = getAccessTokenApi();

  var serverClose = false;

  useEffect(() => {
    var loadCall = false;
    console.log(evento);
    getEventByUrlApi(evento).then((response) => {
      setEvent(response.event);
    });

    const peer = new Peer(evento);

    //setPeerSave(peer);

    peer.on("open", (id) => {
      console.log("peer open");
      setPeerId(id);
    });

    peer.on("connection", (newConnection) => {
      console.log("peer connect");
      setConnection(newConnection);
    });

    peer.on("close", () => {
      console.log("peer close");
      console.log(peer);
    });

    peer.on("error", function (err) {
      console.log(err);
    });

    peer.on("disconnected", function () {
      console.log("peer disconnect");
    });

    // peer.on("connection", function (dataConnectionOpen) {
    //   console.log(dataConnectionOpen);
    //   dataConnectionOpen.on("open", function () {
    //     // Send messages
    //     //dataConnection.send('Hello from markers-page!');
    //     console.log(dataConnectionOpen);
    //     setDataConnection(dataConnectionOpen);
    //   });
    // });

    peer.on("call", (call) => {
      console.log("peer call");
      setRemotePeerIdValue(call.peer);
      var getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;

      getUserMedia({ video: false, audio: true }, (mediaStream) => {
        currentUserVideoRef.current.srcObject = mediaStream;
        currentUserVideoRef.current.play();

        mediaStreamRef.current = mediaStream;
        setMediaStream(mediaStream);

        call.answer(mediaStream);
        call.on("stream", function (remoteStream) {
          console.log("call stream");
          if (!loadCall) {
            console.log("call stream " + loadCall);
            remoteVideoRef.current.srcObject = remoteStream;
            remoteVideoRef.current.play();
            loadCall = true;
          }
          setIsRemoteConnect(true);

          setIsInCall(true);

          // if (videoEnabled) {
          //   if (mediaStream) {
          //     const videoTrack = mediaStream.getVideoTracks()[0];
          //     videoTrack.enabled = !videoEnabled;
          //     setVideoEnabled(!videoEnabled);
          //   }
          // }

          const recorder = new MediaRecorder(remoteStream);
          setMediaRecorder(recorder);

          recorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              setRecordedChunks((prevChunks) => [...prevChunks, event.data]);
            }
          };
        });
        call.on("close", function () {
          console.log(serverClose);
          if (!serverClose) {
            console.log("call close");
            loadCall = false;
            openNotificationWithIcon("error", "La conexión ha finalizado!");
            setAudioEnabled(true);
            setVideoEnabled(true);
          }
        });
        call.on("error", function (err) {
          console.log(err);
        });
      });
    });
    peerInstance.current = peer;
  }, []);

  const call = (remotePeerId) => {
    console.log(remotePeerId);
    setCalling(true);
    //var getUserMedia = navigator.getUserMedia || navigator.mozGetUserMedia;

    //console.log(getUserMedia);
    try {
      var getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;

      navigator.mediaDevices
        .getUserMedia({ video: false, audio: true })
        .then(function (mediaStream) {
          //console.log(mediaStream);

          currentUserVideoRef.current.srcObject = mediaStream;
          currentUserVideoRef.current.play();

          setMediaStream(mediaStream);

          //peerInstance.current.call(remotePeerId, mediaStream);
          const call = peerInstance.current.call(remotePeerId, mediaStream);
          //setDataConnection(call);
          setCalling(false);
          setIsInCall(true);

          // if (videoEnabled) {
          //   if (videoEnabled) {
          //     if (mediaStream) {
          //       const videoTrack = mediaStream.getVideoTracks()[0];
          //       videoTrack.enabled = !videoEnabled;
          //       setVideoEnabled(!videoEnabled);
          //     }
          //   }
          // }
          // call.on("stream", (remoteStream) => {
          //   console.log("call stream 2");
          //   if (!isRemoteConnect) {
          //     remoteVideoRef.current.srcObject = remoteStream;
          //     remoteVideoRef.current.play();
          //   }
          //   setIsRemoteConnect(true);

          //   const recorder = new MediaRecorder(remoteStream);
          //   setMediaRecorder(recorder);

          //   recorder.ondataavailable = (event) => {
          //     if (event.data.size > 0) {
          //       setRecordedChunks((prevChunks) => [...prevChunks, event.data]);
          //     }
          //   };
          // });
          call.on("close", function () {
            console.log("call close 2");
          });
          call.on("error", function (err) {
            console.log(err);
          });
        })
        .catch(function (err) {
          console.log(err);
          setCalling(false);
          setModalTitle("Revisa los permisos de la cámara y micrófono");
          setmodalContent(
            <Row gutter={24}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ textAlign: "center" }}
              >
                <Result
                  status="warning"
                  title="Los permisos del navegador no son correctos"
                  subTitle="Por favor verifica que los permisos de acceso a la cámara y micrófono estén habilitados en el navegador, si estás usando Chrome, puedes verificarlo en el botón a la izquierda de la barra de direcciones:"
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ textAlign: "center" }}
              >
                <img
                  alt="error"
                  src={errorpermisos}
                  style={{ width: "470px" }}
                ></img>
              </Col>
            </Row>
          );
          setIsVisibleModal(true);
        });

      UpdateEventApi(token, { start: moment() }, event.id);
    } catch (err) {
      console.log(err);
    }
  };

  const startRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.start();
      setIsRecording(true);
      setIsDownload(false);
      setIsStartVideo(true);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.stop();
      setIsRecording(false);
    }
  };

  const downloadRecording = () => {
    const blob = new Blob(recordedChunks, { type: "video/webm" });
    let filename = `inspección_${moment().format("YYYY-MM-DD--HH-mm")}.webm`;
    saveAs(blob, filename);
    setIsDownload(true);
  };

  const toggleVideo = () => {
    if (mediaStream) {
      const videoTrack = mediaStream.getVideoTracks()[0];
      videoTrack.enabled = !videoEnabled;
      setVideoEnabled(!videoEnabled);
    }
  };

  const toggleAudio = () => {
    if (mediaStream) {
      const audioTrack = mediaStream.getAudioTracks()[0];
      audioTrack.enabled = !audioEnabled;
      setAudioEnabled(!audioEnabled);
    }
  };

  const endCall = () => {
    if (!isStartVideo && isRemoteConnect) {
      openNotificationWithIcon(
        "error",
        "No se puede finalizar la llamada porque no ha inciado la grabación de un video!"
      );
      return;
    }

    if (isRecording) {
      openNotificationWithIcon(
        "error",
        "No se puede finalizar la llamada porque tiene una grabación en curso, primero detenga la grabación y descargue el archivo!"
      );
      return;
    }

    if (!isDownload) {
      openNotificationWithIcon(
        "error",
        "No se puede finalizar la llamada porque no ha descargado el video generado!"
      );
      return;
    }

    mediaStream.getVideoTracks().forEach((track) => {
      track.stop();
    });

    mediaStream.getTracks().forEach((track) => track.stop());

    if (mediaStreamRef.current) {
      mediaStreamRef.current.getVideoTracks().forEach((track) => {
        track.stop();
      });

      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
    }

    // mediaStreamRef.getVideoTracks().forEach((track) => {
    //   track.stop();
    // });

    // mediaStreamRef.getTracks().forEach((track) => track.stop());

    serverClose = true;
    console.log(serverClose);
    console.log(mediaStreamRef);
    //mediaStream.stop();
    //mediaStreamRef.current.close();

    if (connection) {
      connection.close();
    }
    if (peerInstance.current) {
      peerInstance.current.disconnect();
      peerInstance.current.destroy();
    }
    console.log(remoteVideoRef.current.srcObject);
    //console.log(currentUserVideoRef.current.srcObject);
    if (remoteVideoRef.current.srcObject != null) {
      //remoteVideoRef.current.stop();
      remoteVideoRef.current.srcObject = null;
    }
    if (currentUserVideoRef.current.srcObject != null) {
      currentUserVideoRef.current.srcObject = null;
    }

    setIsInCall(false);

    UpdateEventApi(token, { end: moment() }, event.id).then((response) => {
      navigate("/admin/eventos/admin");
    });
  };

  const captureSnapshot = () => {
    //console.log(remoteVideoRef.current.videoHeight);
    if (remoteVideoRef.current && canvasRef.current) {
      const canvas = canvasRef.current;
      canvas.width = remoteVideoRef.current.videoWidth;
      canvas.height = remoteVideoRef.current.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(
        remoteVideoRef.current,
        0,
        0,
        canvas.width,
        canvas.height
      );

      // Convertir la imagen en un Blob y descargarla
      canvas.toBlob((blob) => {
        if (blob) {
          saveAs(blob, "snapshot.png");
        }
      }, "image/png");
    }
  };

  const openNotificationWithIcon = (type, text) => {
    notification[type]({
      message: "Notificación SafeRisk",
      description: text,
      placement: "bottomRight",
    });
  };

  const toggleClientVideo = () => {
    console.log(connection);
    if (connection) {
      connection.send("video");
      setVideoClientEnabled(!videoClientEnabled);
    }
  };

  const toggleClientAudio = () => {
    if (connection) {
      connection.send("audio");
      setAudioClientEnabled(!audioClientEnabled);
    }
  };

  const switchClientCamera = () => {
    if (connection) {
      connection.send("switch");
    }
  };

  return (
    <div className="conference">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/admin/eventos/admin">Conferencias</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Video llamada</Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Card
            size="small"
            title="Correo"
            headStyle={{ backgroundColor: "#175e92", color: "white" }}
            bodyStyle={{ fontWeight: "Bold", fontSize: "16px" }}
          >
            {event ? event.mail : "No existe la conferencia"}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Card
            size="small"
            title="Teléfono móvil"
            headStyle={{ backgroundColor: "#175e92", color: "white" }}
            bodyStyle={{ fontWeight: "Bold", fontSize: "16px" }}
          >
            {event ? event.mobil : ""}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Card
            size="small"
            title="Código de la conferencia"
            headStyle={{ backgroundColor: "#175e92", color: "white" }}
            bodyStyle={{ fontWeight: "Bold", fontSize: "16px" }}
          >
            {event ? event.code : ""}
          </Card>
        </Col>
      </Row>
      <br />
      {!isInCall && (
        <Row gutter={24}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ textAlign: "center" }}
          >
            {/* <button onClick={() => call(remotePeerIdValue)}>Call</button> */}
            <Button
              style={{ width: "200px", height: "200px" }}
              shape="circle"
              icon={<VideoCameraOutlined style={{ fontSize: "60px" }} />}
              size="large"
              onClick={() => call(remotePeerIdValue)}
              loading={calling}
            />
          </Col>

          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ textAlign: "center" }}
          >
            {/* <button onClick={() => call(remotePeerIdValue)}>Call</button> */}
            <h2>Iniciar el peritaje</h2>
          </Col>
        </Row>
      )}
      <Row gutter={24}>
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          {/* <button onClick={() => call(remotePeerIdValue)}>Call</button> */}
          <div
            className={
              isInCall
                ? "video-container-conference bakcground-video-image-conference"
                : "video-container-conference"
            }
          >
            <video
              ref={remoteVideoRef}
              className="background-video-conference"
            />
            {/* <canvas
              ref={canvasRef}
              className="background-video-conference"
              style={{ display: "none" }}
            /> */}
            <video
              ref={currentUserVideoRef}
              className="overlay-video-conference"
              muted
            />
            {isInCall && (
              <div className="control-buttons">
                {/* <Button
                  onClick={toggleVideo}
                  type="default"
                  icon={
                    videoEnabled ? <VideoCameraOutlined /> : <CloseOutlined />
                  }
                >
                  {videoEnabled ? "Apagar Video" : "Habilitar Video"}
                </Button> */}
                {/* <button onClick={toggleVideo}>
                  {videoEnabled ? "Disable Video" : "Enable Video"}
                </button> */}
                <Button
                  onClick={toggleAudio}
                  type="default"
                  icon={
                    audioEnabled ? <AudioOutlined /> : <AudioMutedOutlined />
                  }
                >
                  {audioEnabled ? "Silenciar Audio" : "Habilitar Audio"}
                </Button>
                {/* <button onClick={toggleAudio}>
                  {audioEnabled ? "Mute Audio" : "Unmute Audio"}
                </button> */}
                <Button
                  onClick={endCall}
                  type="primary"
                  danger
                  icon={<CloseCircleOutlined />}
                >
                  Finalizar llamada
                </Button>
                {/* <button onClick={endCall}>End Call</button> */}
              </div>
            )}
          </div>
        </Col>

        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          {/* <button onClick={() => call(remotePeerIdValue)}>Call</button> */}
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}></Col>
            {isInCall && (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ marginBottom: "10px" }}
              >
                <Button
                  onClick={startRecording}
                  loading={isRecording}
                  type="default"
                  icon={<PlayCircleOutlined />}
                  size="large"
                  style={{ width: "100%" }}
                >
                  Iniciar grabación de video
                </Button>
                {/* <button onClick={startRecording} disabled={isRecording}>
                  Start Recording
                </button> */}
              </Col>
            )}

            {isInCall && (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ marginBottom: "10px" }}
              >
                <Button
                  onClick={stopRecording}
                  disabled={!isRecording}
                  type="default"
                  icon={<PauseCircleOutlined />}
                  size="large"
                  style={{ width: "100%" }}
                >
                  Detener grabación de video
                </Button>
                {/* <button onClick={stopRecording} disabled={!isRecording}>
                  Stop Recording
                </button> */}
              </Col>
            )}

            {isInCall && (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ marginBottom: "10px" }}
              >
                <Button
                  onClick={downloadRecording}
                  disabled={recordedChunks.length === 0}
                  type="default"
                  icon={<DownloadOutlined />}
                  size="large"
                  style={{ width: "100%" }}
                >
                  Descargar video grabado
                </Button>
                {/* <button
                  onClick={downloadRecording}
                  disabled={recordedChunks.length === 0}
                >
                  Download Recording
                </button> */}
              </Col>
            )}
            {isInCall && (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ marginBottom: "10px" }}
              >
                <Button
                  onClick={captureSnapshot}
                  //disabled={recordedChunks.length === 0}
                  type="default"
                  icon={<FileImageOutlined />}
                  size="large"
                  style={{ width: "100%" }}
                >
                  Capturar imagen y descargar
                </Button>
                {/* <button
                  onClick={downloadRecording}
                  disabled={recordedChunks.length === 0}
                >
                  Download Recording
                </button> */}
              </Col>
            )}
            {isInCall && (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ marginBottom: "10px" }}
              >
                <Button
                  onClick={toggleClientVideo}
                  //disabled={recordedChunks.length === 0}
                  type="default"
                  icon={
                    videoClientEnabled ? (
                      <VideoCameraOutlined />
                    ) : (
                      <CloseOutlined />
                    )
                  }
                  size="large"
                  style={{ width: "100%" }}
                >
                  {videoClientEnabled
                    ? "Apagar video cliente"
                    : "Habilitar video cliente"}
                </Button>
                {/* <button
                  onClick={downloadRecording}
                  disabled={recordedChunks.length === 0}
                >
                  Download Recording
                </button> */}
              </Col>
            )}
            {isInCall && (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ marginBottom: "10px" }}
              >
                <Button
                  onClick={toggleClientAudio}
                  //disabled={recordedChunks.length === 0}
                  type="default"
                  icon={
                    audioClientEnabled ? (
                      <AudioOutlined />
                    ) : (
                      <AudioMutedOutlined />
                    )
                  }
                  size="large"
                  style={{ width: "100%" }}
                >
                  {audioClientEnabled
                    ? "Silenciar audio cliente"
                    : "Habilitar audio cliente"}
                </Button>
                {/* <button
                  onClick={downloadRecording}
                  disabled={recordedChunks.length === 0}
                >
                  Download Recording
                </button> */}
              </Col>
            )}
            {isInCall && (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ marginBottom: "10px" }}
              >
                <Button
                  onClick={switchClientCamera}
                  //disabled={recordedChunks.length === 0}
                  type="default"
                  icon={<VideoCameraOutlined />}
                  size="large"
                  style={{ width: "100%" }}
                >
                  Cambiar cámara cliente
                </Button>
                {/* <button
                  onClick={downloadRecording}
                  disabled={recordedChunks.length === 0}
                >
                  Download Recording
                </button> */}
              </Col>
            )}
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
              style={{ minHeight: "500px" }}
            >
              {" "}
              <canvas
                ref={canvasRef}
                className="background-video-conference"
                //style={{ display: "none" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="App">
        {/* <h1>Current user id is {peerId}</h1> */}
        {/* <input
          type="text"
          value={remotePeerIdValue}
          onChange={(e) => setRemotePeerIdValue(e.target.value)}
        /> */}

        {/* <div></div>
        <div>
          <button onClick={startRecording} disabled={isRecording}>
            Start Recording
          </button>
          <button onClick={stopRecording} disabled={!isRecording}>
            Stop Recording
          </button>
          <button
            onClick={downloadRecording}
            disabled={recordedChunks.length === 0}
          >
            Download Recording
          </button>
        </div> */}
      </div>
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}
